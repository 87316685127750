<template>
    <div class="menu-page">
        <h1>FAQ</h1>

        <div>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="default-button" @click.native="openNew">Nova pergunta</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.active`]="{ item }">
                            {{ item.active ? "Sim" : "Não" }}
                        </template>

                        <template v-slot:[`item.answer`]="{ item }">
                            {{ item.answer.length > 60 ? item.answer.substring(0, 59) + '...' : item.answer }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="object = item; dialogCreateUpdateObject = true" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="error" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Apagar</span>
                                </v-tooltip>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="900">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field autocomplete="nope" dense v-model="object.title" label="Título da pergunta" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea autocomplete="nope" dense v-model="object.answer" label="Resposta" outlined hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea autocomplete="nope" rows="10" dense v-model="object.html" label="Resposta em HTML" outlined messages="Aplicativos atualizados irão renderizar a resposta em HTML, enquanto os antigos irão renderizar a resposta em texto" hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="6" sm="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="object.priority" label="Importância (maiores no topo)" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="6" sm="6">
                            <v-switch class="mt-1" v-model="object.active" inset label="Visível" hide-details="auto"></v-switch>
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar pergunta
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a pergunta <b>{{ object.title }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; object.filed = true; createOrUpdateObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "FaqView",

    data() {
        return {
            objects: [],
            dialogCreateUpdateObject: false,
            dialog: false,
            search: '',
            noDataText: 'Sem dados',
            fileInputMessage: '',
            file: null,
            object: {},
            headers: [
                { text: "Título da pergunta", value: "title" },
                { text: "Resposta", value: "answer" },
                { text: "Visível", value: "active" },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true
        };
    },

    components: {},

    async mounted() {
        const user = await Api.getRemoteUser();
        if (user && user.admin && !user.consultant) {
            await this.init();
        }
        else {
            this.$router.push("/");
        }
    },

    methods: {

        async init() {
            this.loading = true;
            const resp = await Api.getFaqs();
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }
                this.objects = resp.message;
            }
            this.loading = false;
        },

        async createOrUpdateObject() {
            if (!this.object.priority) this.object.priority = 1;

            this.buttonLoading = true;
            const resp = await Api.createOrUpdateFaq(this.object);
            if (resp && resp.message) {
                if (resp.error) {
                    this.buttonLoading = false;
                    return emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Operação bem sucedida.");
                }
            }
            await this.init();
            this.buttonLoading = false;
            this.dialogCreateUpdateObject = false;
        },

        openNew() {
            this.object = {};
            this.dialogCreateUpdateObject = true;
        }
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

.item-image {
    max-height: 100px;
    max-width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  