<template>
    <div class="menu-page">
        <h1>Notificações Push</h1>

        <div>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="default-button" @click.native="openNew">Nova notificação</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.sentDate`]="{ item }">
                            {{ item.status === 'Agendado' ? new Date(item.scheduledTo).toLocaleString('pt-BR') : new Date(item.sentDate).toLocaleString('pt-BR') }}
                        </template>

                        <template v-slot:[`item.answer`]="{ item }">
                            {{ item.answer.length > 60 ? item.answer.substring(0, 59) + '...' : item.answer }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <!--
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="object = item; dialogCreateUpdateObject = true" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>
                                -->

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="error" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Apagar</span>
                                </v-tooltip>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="900">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field autocomplete="nope" dense v-model="object.title" label="Título da notificação" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea autocomplete="nope" dense v-model="object.body" label="Mensagem" outlined hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="12" class="d-flex">
                            <div class="image-viewer mr-4">
                                <img class="image-thumb" contain :src="object.image" />
                            </div>
                            <v-file-input prepend-icon="mdi-camera-outline" dense accept="image/*" :label="object.image ? 'Trocar imagem' : 'Adicionar imagem'" v-model="image" @change="uploadImage"></v-file-input>
                        </v-col>

                        <v-col cols="12">
                            <label><b>Para quem essa notificação será enviada?</b></label>
                            <v-radio-group v-model="object.type" column>
                                <v-radio label="Para todos os dispositivos" value="all"></v-radio>
                                <v-radio label="Para um usuário" value="user"></v-radio>
                            </v-radio-group>
                        </v-col>

                        <v-col cols="12" v-if="object.type == 'user'">
                            <v-text-field autocomplete="nope" dense v-model="object.user_email" label="E-mail do usuário na plataforma" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <label><b>Quando a notificação deverá ser enviada?</b></label>
                            <v-radio-group v-model="object.scheduled" column>
                                <v-radio label="Agora" :value="false"></v-radio>
                                <v-radio label="Agendar" :value="true"></v-radio>
                            </v-radio-group>

                            <v-datetime-picker v-if="object.scheduled" label="Data e hora do envio" v-model="object.scheduledTo" date-format="dd/MM/yyyy" :datePickerProps="{locale: 'pt-BR'}" :timePickerProps="{format: '24hr'}">
                                <template slot="dateIcon">
                                    <v-icon>mdi-calendar</v-icon>
                                </template>

                                <template slot="timeIcon">
                                    <v-icon>mdi-clock-outline</v-icon>
                                </template>

                                <template slot="actions" slot-scope="{ parent }">
                                    <v-btn color="error lighten-1" outlined @click.native="parent.clearHandler">Cancelar</v-btn>
                                    <v-btn color="success darken-1" @click="parent.okHandler">OK</v-btn>
                                </template>
                            </v-datetime-picker>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar notificação
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar essa notificação? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; object.filed = true; createOrUpdateObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "FaqView",

    data() {
        return {
            objects: [],
            dialogCreateUpdateObject: false,
            dialog: false,
            datetime: null,
            search: '',
            noDataText: 'Sem notificações',
            object: { type: 'all', scheduled: false, scheduledTo: null },
            image: null,
            headers: [
                { text: "Título", value: "title" },
                { text: "Corpo da mensagem", value: "body" },
                { text: "Status", value: "status" },
                { text: "Envio previsto", value: "sentDate" },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true
        };
    },

    components: {},

    async mounted() {
        const user = await Api.getRemoteUser();
        if (user && user.admin && !user.consultant) {
            await this.init();
        }
        else {
            this.$router.push("/");
        }
    },

    methods: {

        async init() {
            this.loading = true;
            const resp = await Api.getNotifications();
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }
                this.objects = resp.message;
            }
            this.loading = false;
        },

        openNew() {
            this.object = { type: 'all', scheduled: false };
            this.image = null;
            this.dialogCreateUpdateObject = true;
        },

        async uploadImage(data) {
            if (!this.image) {
                delete this.object.image;
                return;
            }

            this.loading = true;
            let resp = await Api.uploadImage(data);
            this.loading = false;

            if (resp && !resp.error && resp.message) this.object.image = resp.message;
            if (resp && resp.error) emitToastr("error", "Erro ao carregar a imagem. Tente novamente.");
        },

        async createOrUpdateObject() {
            this.buttonLoading = true;
            const resp = await Api.createOrUpdateNotification(this.object);
            this.buttonLoading = false;
            if (resp && resp.message) {
                emitToastr('success', "Operação bem sucedida.");
                await this.init();
                this.dialogCreateUpdateObject = false;
            }
        },
    },
};
</script>
  
<style scoped>
.image-viewer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image-thumb {
    max-height: 90px;
    max-width: 80vw;
    border-radius: 4px;
}

@media only screen and (max-width: 600px) {}
</style>
  