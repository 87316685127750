<template>
    <div class="menu-page pa-4" style="max-width: 100vw; display: flex; flex-direction: column;align-items: center;">
        <div class="tool-container">
            <v-row>
                <v-col cols="12" class="text-center pb-0">
                    <div class="tool-title pb-0">Cálculo do extrato seco desengordurado</div>
                </v-col>
            </v-row>

            <v-row class="mt-8">
                <v-col cols="12">
                    <v-card>
                        <v-card-title>Temperatura °C</v-card-title>
                        <v-card-text class="mt-6">
                            <v-slider v-model="temperatura" min="1" max="35" step="1" thumb-label="always" @input="calc" hide-details="auto"></v-slider>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <v-card>
                        <v-card-title>Densidade</v-card-title>
                        <v-card-text class="mt-6">
                            <v-slider v-model="densidade" min="17" max="38" step="1" thumb-label="always" @input="calc" hide-details="auto"></v-slider>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <v-card>
                        <v-card-title>Densidade corrigida a 15 °C</v-card-title>
                        <v-card-text class="text-center black--text">
                            <h1>{{ densidade_corrigida }}</h1>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <v-card>
                        <v-card-title>Cálculo</v-card-title>
                        <v-card-text>
                            <v-radio-group v-model="tipo" class="mt-0" @change="calc">
                                <v-radio label="Sólidos em leite" value="leite"></v-radio>
                                <v-radio label="Sólidos em soro" value="soro"></v-radio>
                            </v-radio-group>

                            <v-text-field class="mb-3" v-model="gordura" step="0.1" type="number" outlined label="Gordura (%m/v)" @input="calc" hide-details="auto"></v-text-field>

                            <v-text-field readonly v-model="ts" outlined label="Sólidos Totais (% m/v)" hide-details="auto" messages="Calculado automaticamente"></v-text-field>

                            <v-text-field class="mb-3" readonly v-model="tsd" outlined label="Sólidos totais Desengordurados (% m/v)" hide-details="auto" messages="Calculado automaticamente"></v-text-field>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>



            <!--
        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar pergunta
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a pergunta <b>{{ object.title }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; object.filed = true; createOrUpdateObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        -->
        </div>
    </div>
</template>

<script>
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "CalculoExtratoSecoDesengordurado",

    data() {
        return {
            loading: false,
            dialog: false,
            temperatura: 1,
            densidade: 17,
            densidade_corrigida: 17,
            gordura: null,
            ts: null,
            tsd: null,
            tipo: 'leite',
            tabela: {
                "17": [14.2, 14.4, 14.6, 14.8, 15, 15.2, 15.4, 15.6, 15.8, 16, 16.2, 16.4, 16.6, 16.8, 17, 17.2, 17.4, 17.6, 17.8, 18, 18.2, 18.4, 18.6, 18.8, 19, 19.2, 19.4, 19.6, 19.8, 20.1, 20.4, 20.7, 21, 21.3, 21.6],
                "18": [15.2, 15.4, 15.6, 15.8, 16, 16.2, 16.4, 16.6, 16.8, 17, 17.2, 17.4, 17.6, 17.8, 18, 18.2, 18.4, 18.6, 18.8, 19, 19.2, 19.4, 19.6, 19.8, 20, 20.2, 20.4, 20.6, 20.8, 21.1, 21.4, 21.7, 22, 22.3, 22.6],
                "19": [16.2, 16.4, 16.6, 16.8, 17, 17.2, 17.4, 17.6, 17.8, 18, 18.2, 18.4, 18.6, 18.8, 19, 19.2, 19.4, 19.6, 19.8, 20, 20.2, 20.4, 20.6, 20.8, 21, 21.2, 21.4, 21.6, 21.8, 22.1, 22.4, 22.7, 23, 23.3, 23.6],
                "20": [17.2, 17.4, 17.6, 17.8, 18, 18.2, 18.4, 18.6, 18.8, 19, 19.2, 19.4, 19.6, 19.8, 20, 20.2, 20.4, 20.6, 20.8, 21, 21.2, 21.4, 21.6, 21.8, 22, 22.2, 22.4, 22.6, 22.8, 23.1, 23.4, 23.7, 24, 24.3, 24.6],
                "21": [18.2, 18.4, 18.6, 18.8, 19, 19.2, 19.4, 19.6, 19.8, 20, 20.2, 20.4, 20.6, 20.8, 21, 21.2, 21.4, 21.6, 21.8, 22, 22.2, 22.4, 22.6, 22.8, 23, 23.2, 23.4, 23.6, 23.8, 24.1, 24.4, 24.7, 25, 25.3, 25.6],
                "22": [19.2, 19.4, 19.6, 19.8, 20, 20.2, 20.4, 20.6, 20.8, 21, 21.2, 21.4, 21.6, 21.8, 22, 22.2, 22.4, 22.6, 22.8, 23, 23.2, 23.4, 23.6, 23.8, 24, 24.2, 24.4, 24.6, 24.8, 25.1, 25.4, 25.7, 26, 26.3, 26.6],
                "23": [20.2, 20.4, 20.6, 20.8, 21, 21.2, 21.4, 21.6, 21.8, 22, 22.2, 22.4, 22.6, 22.8, 23, 23.2, 23.4, 23.6, 23.8, 24, 24.2, 24.4, 24.6, 24.8, 25, 25.2, 25.4, 25.6, 25.8, 26.1, 26.4, 26.7, 27, 27.3, 27.6],
                "24": [21.2, 21.4, 21.6, 21.8, 22, 22.2, 22.4, 22.6, 22.8, 23, 23.2, 23.4, 23.6, 23.8, 24, 24.2, 24.4, 24.6, 24.8, 25, 25.2, 25.4, 25.6, 25.8, 26, 26.2, 26.4, 26.6, 26.8, 27.1, 27.4, 27.7, 28, 28.3, 28.6],
                "25": [22.2, 22.4, 22.6, 22.8, 23, 23.2, 23.4, 23.6, 23.8, 24, 24.2, 24.4, 24.6, 24.8, 25, 25.2, 25.4, 25.6, 25.8, 26, 26.2, 26.4, 26.6, 26.8, 27, 27.2, 27.4, 27.6, 27.8, 28.1, 28.4, 28.7, 29, 29.3, 29.6],
                "26": [23.2, 23.4, 23.6, 23.8, 24, 24.2, 24.4, 24.6, 24.8, 25, 25.2, 25.4, 25.6, 25.8, 26, 26.2, 26.4, 26.6, 26.8, 27, 27.2, 27.4, 27.6, 27.8, 28, 28.2, 28.4, 28.6, 28.8, 29.1, 29.4, 29.7, 30, 30.3, 30.6],
                "27": [24.2, 24.4, 24.6, 24.8, 25, 25.2, 25.4, 25.6, 25.8, 26, 26.2, 26.4, 26.6, 26.8, 27, 27.2, 27.4, 27.6, 27.8, 28, 28.2, 28.4, 28.6, 28.8, 29, 29.2, 29.4, 29.6, 29.8, 30.1, 30.4, 30.7, 31, 31.3, 31.6],
                "28": [25.2, 25.4, 25.6, 25.8, 26, 26.2, 26.4, 26.6, 26.8, 27, 27.2, 27.4, 27.6, 27.8, 28, 28.2, 28.4, 28.6, 28.8, 29, 29.2, 29.4, 29.6, 29.8, 30, 30.2, 30.4, 30.6, 30.8, 31.1, 31.4, 31.7, 32, 32.3, 32.6],
                "29": [26.2, 26.4, 26.6, 26.8, 27, 27.2, 27.4, 27.6, 27.8, 28, 28.2, 28.4, 28.6, 28.8, 29, 29.2, 29.4, 29.6, 29.8, 30, 30.2, 30.4, 30.6, 30.8, 31, 31.2, 31.4, 31.6, 31.8, 32.1, 32.4, 32.7, 33, 33.3, 33.6],
                "30": [27.2, 27.4, 27.6, 27.8, 28, 28.2, 28.4, 28.6, 28.8, 29, 29.2, 29.4, 29.6, 29.8, 30, 30.2, 30.4, 30.6, 30.8, 31, 31.2, 31.4, 31.6, 31.8, 32, 32.2, 32.4, 32.6, 32.8, 33.1, 33.4, 33.7, 34, 34.3, 34.6],
                "31": [28.2, 28.4, 28.6, 28.8, 29, 29.2, 29.4, 29.6, 29.8, 30, 30.2, 30.4, 30.6, 30.8, 31, 31.2, 31.4, 31.6, 31.8, 32, 32.2, 32.4, 32.6, 32.8, 33, 33.2, 33.4, 33.6, 33.8, 34.1, 34.4, 34.7, 35, 35.3, 35.6],
                "32": [29.2, 29.4, 29.6, 29.8, 30, 30.2, 30.4, 30.6, 30.8, 31, 31.2, 31.4, 31.6, 31.8, 32, 32.2, 32.4, 32.6, 32.8, 33, 33.2, 33.4, 33.6, 33.8, 34, 34.2, 34.4, 34.6, 34.8, 35.1, 35.4, 35.7, 36, 36.3, 36.6],
                "33": [30.2, 30.4, 30.6, 30.8, 31, 31.2, 31.4, 31.6, 31.8, 32, 32.2, 32.4, 32.6, 32.8, 33, 33.2, 33.4, 33.6, 33.8, 34, 34.2, 34.4, 34.6, 34.8, 35, 35.2, 35.4, 35.6, 35.8, 36.1, 36.4, 36.7, 37, 37.3, 37.6],
                "34": [31.2, 31.4, 31.6, 31.8, 32, 32.2, 32.4, 32.6, 32.8, 33, 33.2, 33.4, 33.6, 33.8, 34, 34.2, 34.4, 34.6, 34.8, 35, 35.2, 35.4, 35.6, 35.8, 36, 36.2, 36.4, 36.6, 36.8, 37.1, 37.4, 37.7, 38, 38.3, 38.6],
                "35": [32.2, 32.4, 32.6, 32.8, 33, 33.2, 33.4, 33.6, 33.8, 34, 34.2, 34.4, 34.6, 34.8, 35, 35.2, 35.4, 35.6, 35.8, 36, 36.2, 36.4, 36.6, 36.8, 37, 37.2, 37.4, 37.6, 37.8, 38.1, 38.4, 38.7, 39, 39.3, 39.6],
                "36": [33.2, 33.4, 33.6, 33.8, 34, 34.2, 34.4, 34.6, 34.8, 35, 35.2, 35.4, 35.6, 35.8, 36, 36.2, 36.4, 36.6, 36.8, 37, 37.2, 37.4, 37.6, 37.8, 38, 38.2, 38.4, 38.6, 38.8, 39.1, 39.4, 39.7, 40, 40.3, 40.6],
                "37": [34.2, 34.4, 34.6, 34.8, 35, 35.2, 35.4, 35.6, 35.8, 36, 36.2, 36.4, 36.6, 36.8, 37, 37.2, 37.4, 37.6, 37.8, 38, 38.2, 38.4, 38.6, 38.8, 39, 39.2, 39.4, 39.6, 39.8, 40.1, 40.4, 40.7, 41, 41.3, 41.6],
                "38": [35.2, 35.4, 35.6, 35.8, 36, 36.2, 36.4, 36.6, 36.8, 37, 37.2, 37.4, 37.6, 37.8, 38, 38.2, 38.4, 38.6, 38.8, 39, 39.2, 39.4, 39.6, 39.8, 40, 40.2, 40.4, 40.6, 40.8, 41.1, 41.4, 41.7, 42, 42.3, 42.6]
            }
        };
    },

    components: {},

    async mounted() {

    },

    methods: {
        calc() {
            const linha = this.tabela[this.densidade.toString()];
            const valor = linha[this.temperatura - 1];
            this.densidade_corrigida = valor;

            if (!this.gordura) {
                this.ts = null;
                this.tsd = null;
            }

            if (this.gordura && !isNaN(this.gordura)) {
                if (this.tipo == 'leite') {
                    this.ts = 1.2 * this.gordura + 2.665 * ((100 * ((1000 + this.densidade_corrigida) / 1000) - 100) / ((1000 + this.densidade_corrigida) / 1000));
                }

                if (this.tipo == 'soro') {
                    this.ts = 1.152 * this.gordura + 2.302 * ((100 * ((1000 + this.densidade_corrigida) / 1000) - 100) / ((1000 + this.densidade_corrigida) / 1000));
                }
            }

            if (!isNaN(this.ts) && this.ts) {
                this.ts = Number(this.ts);
                this.tsd = this.ts - this.gordura;
                this.ts = this.ts.toFixed(2);
                this.tsd = this.tsd.toFixed(2);
            }
        }
    },
};
</script>

<style scoped>
.tool-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    align-items: center;
}
.tool-title {
    font-size: 22px;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {}
</style>