<template>
    <div class="menu-page">
        <h1>Testes de Imunocromatografia</h1>

        <div>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="default-button" @click.native="openNew">Novo teste</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.active`]="{ item }">
                            {{ item.active ? "Sim" : "Não" }}
                        </template>

                        <template v-slot:[`item.details.description`]="{ item }">
                            {{ item.details.description.length > 60 ? item.details.description.substring(0, 59) + '...' : item.details.description }}
                        </template>

                        <template v-slot:[`item.categoryId`]="{ item }">
                            {{ categories.find(i => i.id == item.categoryId).name }}
                        </template>

                        <template v-slot:[`item.created`]="{ item }">
                            {{ new Date(item.created).toLocaleString() }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="edit(item)" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="error" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Apagar</span>
                                </v-tooltip>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="900">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="object.id" label="Id único" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-autocomplete v-model="object.categoryId" :items="categories" item-text="name" item-value="id" outlined label="Categoria" dense hide-details="auto" :return-object="false"></v-autocomplete>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field autocomplete="nope" dense v-model="object.name" label="Nome do teste" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field autocomplete="nope" dense v-model="object.code" label="Código do teste" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea autocomplete="nope" rows="3" dense v-model="object.details.description" label="Descrição do teste" outlined hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea autocomplete="nope" rows="3" dense v-model="object.details.techSpecs" label="Especificações técnicas" outlined hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field autocomplete="nope" dense v-model="object.details.websiteLink" label="Link para o site" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field autocomplete="nope" dense v-model="object.details.tutorialLink" label="Link para o tutorial" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="object.positiveValue" label="Valor positivo" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="object.negativeValue" label="Valor negativo" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <label>Itens testados</label>
                            <br>
                            <v-btn-toggle mandatory class="mt-2 mb-2" active-class="active-btn" v-model="itemQtd" borderless>
                                <v-btn color="#e5e5e5" value="1" @click="setItemQtd(1)">
                                    <span class="hidden-sm-and-down">1</span>
                                </v-btn>

                                <v-btn color="#e5e5e5" value="2" @click="setItemQtd(2)">
                                    <span class="hidden-sm-and-down">2</span>
                                </v-btn>

                                <v-btn color="#e5e5e5" value="3" @click="setItemQtd(3)">
                                    <span class="hidden-sm-and-down">3</span>
                                </v-btn>

                                <v-btn color="#e5e5e5" value="4" @click="setItemQtd(4)">
                                    <span class="hidden-sm-and-down">4</span>
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>

                        <v-col cols="12" v-for="(item, index) in object.stripItemList" :key="index">
                            <v-text-field autocomplete="nope" dense v-model="item.itemName" label="Nome do item" outlined hide-details="auto"></v-text-field>
                        </v-col>


                        <v-col cols="6" sm="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="object.priority" label="Importância (maiores no topo)" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-switch class="mt-1" v-model="object.active" inset label="Ativo?" hide-details="auto"></v-switch>
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar teste
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o teste <b>{{ object.name }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; object.filed = true; createOrUpdateObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "StripTestsView",

    data() {
        return {
            objects: [],
            dialogCreateUpdateObject: false,
            dialog: false,
            search: '',
            noDataText: 'Sem dados',
            fileInputMessage: '',
            file: null,
            itemQtd: 1,
            categories: [
                { id: 1, name: "Resíduos de antibiótico no leite" },
                { id: 2, name: "Resíduos de pesticidas" },
                { id: 3, name: "Diagnóstico de mastite bovina" },
                { id: 4, name: "Micotoxinas e microbiologia" }
            ],
            object: { active: true, stripItemList: [], details: { description: "", techSpecs: "", websiteLink: "", tutorialLink: "https://youtu.be/hXQkNj6rVsU" } },
            headers: [
                { text: "Id", value: "id" },
                { text: "Nome", value: "name" },
                { text: "Código", value: "code" },
                { text: "Categoria", value: "categoryId" },
                { text: "Descrição", value: "details.description" },
                { text: "Ativo", value: "active" },
                { text: "Criação", value: "created" },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true
        };
    },

    components: {},

    async mounted() {
        const user = await Api.getRemoteUser();
        if (user && user.admin && !user.consultant) {
            await this.init();
        }
        else {
            this.$router.push("/");
        }
    },
    methods: {

        async init() {
            this.loading = true;
            const resp = await Api.getStripTests();
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }
                this.objects = resp.message;
            }
            this.loading = false;
        },

        async createOrUpdateObject() {
            if (!this.object.id) return emitToastr("error", "O campo id é obrigatório");
            if (!this.object.categoryId) return emitToastr("error", "O campo categoria é obrigatório");
            if (!this.object.name) return emitToastr("error", "O campo nome é obrigatório");
            if (!this.object.details || !this.object.details.description) return emitToastr("error", "O campo descrição é obrigatório");
            if (this.object.details && this.object.details.websiteLink && !this.object.details.websiteLink.includes('https://')) return emitToastr("error", "O campo link para o site precisa iniciar com https://");
            if (this.object.details && this.object.details.tutorialLink && !this.object.details.tutorialLink.includes('https://')) return emitToastr("error", "O campo link para o tutorial precisa iniciar com https://");

            if (!this.object.positiveValue) return emitToastr("error", "O campo valor positivo é obrigatório");
            if (!this.object.negativeValue) return emitToastr("error", "O campo valor negativo é obrigatório");

            if (!this.object.stripItemList || this.object.stripItemList.length < 1) return emitToastr("error", "O campo itens de teste é obrigatório");

            this.buttonLoading = true;
            const resp = await Api.createOrUpdateStripTest(this.object);
            console.log(resp)

            if (resp && resp.message) {
                if (resp.error) {
                    this.buttonLoading = false;
                    return emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Operação bem sucedida.");
                }
            }
            await this.init();
            this.buttonLoading = false;
            this.dialogCreateUpdateObject = false;
        },

        openNew() {
            this.itemQtd = "1";
            this.object = { active: true, stripItemList: [], details: { description: "", techSpecs: "", websiteLink: "", tutorialLink: "https://youtu.be/hXQkNj6rVsU" } };
            this.dialogCreateUpdateObject = true;
        },

        edit(item) {
            this.object = item;
            this.itemQtd = this.object.stripItemList.length.toString();
            this.dialogCreateUpdateObject = true;
        },

        setItemQtd(qtd) {
            this.itemQtd = qtd.toString();
            if (this.itemQtd < this.object.stripItemList.length) this.object.stripItemList.length = this.itemQtd;
            if (this.itemQtd > this.object.stripItemList.length) {
                for (let i = 0; i < this.itemQtd; i++) {
                    if (!this.object.stripItemList[i]) this.object.stripItemList[i] = { itemName: `Insira o nome do item ${i + 1}` };
                }
            }
            this.$forceUpdate();
        }
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

.item-image {
    max-height: 100px;
    max-width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.active-btn {
    color: white !important;
    background-color: #5c69e0 !important;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  