<template>
    <div class="menu-page">
        <h1>Política de Privacidade</h1>

        <div class="mt-8 mb-2">
            <v-textarea rows="18" outlined v-model="object.content"></v-textarea>
            <v-btn depressed large class="default-button" @click.native="savePrivacyPolicy">Salvar</v-btn>
        </div>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "PrivacyPolicyView",

    data() {
        return {
            object: { content: "" },
            buttonLoading: false,
            loading: true
        };
    },

    components: {},

    async mounted() {
        const user = await Api.getRemoteUser();
        if (user && user.admin && !user.consultant) {
            await this.init();
        }
        else {
            this.$router.push("/");
        }
    },

    methods: {

        async init() {
            this.loading = true;
            const resp = await Api.getPrivacyPolicy();
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }

                this.object = resp.message;
                console.log(this.object)
            }
            this.loading = false;
        },

        async savePrivacyPolicy() {
            const resp = await Api.createPrivacyPolicy(this.object);

            if (resp && resp.error) {
                return emitToastr("error", resp.message);
            }
            else {
                return emitToastr("success", "Política de privacidade atualizada com sucesso!");
            }
        }
    },
};
</script>
  
<style scoped></style>
  