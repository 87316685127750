<template>
    <div class="menu-page">
        <h1>Usuários</h1>


        <div class="mt-6">
            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="default-button" @click.native="object = {}; modalNewObject = true">Novo usuário</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>
                    <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :search="search" :headers="headers" :items="objects" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="object = item; modalNewObject = true" v-bind="attrs" v-on="on"> mdi-account-edit</v-icon>
                                    </template>
                                    <span>Editar usuário</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium color="error" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Apagar</span>
                                </v-tooltip>
                            </div>
                        </template>

                        <template v-slot:[`item.admin`]="{ item }">
                            {{ item.admin ? "Sim" : "Não" }}
                        </template>

                        <template v-slot:[`item.consultant`]="{ item }">
                            {{ item.consultant ? "Sim" : "Não" }}
                        </template>

                        <template v-slot:[`item.created`]="{ item }">
                            {{ new Date(item.created).toLocaleString() }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </div>




        <v-bottom-sheet v-model="modalNewObject">
            <v-sheet class="bottom-modal-container" height="auto">
                <v-row>
                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="object.name" label="Nome" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="object.business_name" label="Empresa" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="object.email" label="E-mail" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="object.password" label="Senha" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="3">
                        <v-switch class="mt-1" v-model="object.admin" inset label="Administrador" hide-details="auto"></v-switch>
                    </v-col>

                    <v-col cols="12" sm="3" v-if="object.admin">
                        <v-switch class="mt-1" v-model="object.consultant" inset label="Permissões de consultor" hide-details="auto"></v-switch>
                    </v-col>

                    <v-col cols="12">
                        <v-btn class="default-button" block @click.native="createOrUpdateUser" :loading="buttonLoading">Salvar usuário</v-btn>
                    </v-col>

                </v-row>
            </v-sheet>
        </v-bottom-sheet>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar loja
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o usuário <b>{{ object.name }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; deleteUser()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "usersView",

    data() {
        return {
            objects: [],
            modalNewObject: false,
            object: {},
            search: "",
            noDataText: "Nenhuma opção",
            loading: false,
            buttonLoading: false,
            dialog: false,
            headers: [
                { text: "Email", value: "email" },
                { text: "Nome", value: "name" },
                { text: "Empresa", value: "business_name" },
                { text: "Administrador?", value: "admin" },
                { text: "Consultor?", value: "consultant" },
                { text: "Criação", value: "created" },
                { text: "Ações", value: "actions" },
            ],
        };
    },


    async mounted() {
        const user = await Api.getRemoteUser();
        if (user && user.admin && !user.consultant) {
            await this.init();
        }
        else {
            this.$router.push("/");
        }
    },

    methods: {

        async init() {
            this.loading = true;
            const resp = await Api.getUsers();

            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }
                this.objects = resp.message.filter(u => !u.filed);
            }
            this.loading = false;
        },

        async createOrUpdateUser() {
            if (!this.object.name) return emitToastr('error', 'Nome obrigatório.');
            if (!this.object.business_name) return emitToastr('error', 'Empresa obrigatória.');
            if (!this.object.email) return emitToastr('error', 'E-mail obrigatório.');

            if (!this.object.admin) this.object.consultant = false;

            this.buttonLoading = true;

            const resp = await Api.createOrUpdateUser(this.object);
            if (resp && resp.message) {
                this.limpaFormulario();
                this.modalNewObject = false;
                this.buttonLoading = false;
                this.init();
                return emitToastr('success', 'Usuário cadastrado com sucesso.');
            }
        },

        limpaFormulario() {
            this.object = {};
        },

        async deleteUser() {
            this.object.filed = true;

            const resp = await Api.createOrUpdateUser(this.object);
            if (resp && !resp.error) {
                this.limpaFormulario();
                this.modalNewObject = false;
                this.buttonLoading = false;
                this.init();
                return emitToastr('success', 'Usuário apagado com sucesso.');
            }
        }
    },
};
</script>
  
<style scoped>
.find-bar {
    display: flex;
    flex-direction: row;
    max-width: 400px;
}

.new-store-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.actions-container {
    display: flex;
    flex-direction: row;

}

.bottom-modal-container {
    padding: 4%;
}

@media only screen and (max-width: 768px) {}
</style>
  