import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Users from "../views/Users.vue";
import Faq from "../views/Faq.vue";
import Notifications from "../views/Notifications.vue";
import StripTests from "../views/StripTests.vue";
import ReagentTests from "../views/ReagentTests.vue";
import Tests from "../views/Tests.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TestImports from "../views/TestImports.vue";
import Profile from "../views/Profile.vue";
import CalculoExtratoSecoDesengordurado from "../views/Utilidades/CalculoExtratoSecoDesengordurado.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import('../views/Login'),
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/usuarios",
    name: "Users",
    component: Users,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/perfil",
    name: "Profile",
    component: Profile,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/notificacoes",
    name: "Notifications",
    component: Notifications,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/testes-imunocromatografia",
    name: "StripTests",
    component: StripTests,
    meta: {
      layout: "menu",
    }
  },
  {
    path: "/analises-fisico-quimicas",
    name: "ReagentTests",
    component: ReagentTests,
    meta: {
      layout: "menu",
    }
  },
  {
    path: "/testes",
    name: "Testes",
    component: Tests,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/politica-de-privacidade",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/importar-testes",
    name: "TestImports",
    component: TestImports,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/utilidades/calculo-extrato-seco-desengordurado",
    name: "CalculoExtratoSecoDesengordurado",
    component: CalculoExtratoSecoDesengordurado,
    meta: {
      layout: "fullscreen",
    },
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login", "/password-recovery"];
  const authRequired = !publicPages.includes(to.path) && !to.path.includes("/utilidades/");
  const loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
