<template>
    <div class="menu-page">
        <h1>Dashboard</h1>
        
        <v-row class="mb-2">
            <v-col cols="12">
                <v-btn :class="`ma-1 ${interval == 1 ? 'default-button' : ''}`" @click.native="getReport(1)" :loading="loading">Hoje</v-btn>
                <v-btn :class="`ma-1 ${interval == 7 ? 'default-button' : ''}`" @click.native="getReport(7)" :loading="loading">7 dias</v-btn>
                <v-btn :class="`ma-1 ${interval == 30 ? 'default-button' : ''}`" @click.native="getReport(30)" :loading="loading">30 dias</v-btn>
                <v-btn :class="`ma-1 ${interval == 90 ? 'default-button' : ''}`" @click.native="getReport(90)" :loading="loading">3 meses</v-btn>
                <v-btn :class="`ma-1 ${interval == 180 ? 'default-button' : ''}`" @click.native="getReport(180)" :loading="loading">6 meses</v-btn>
                <v-btn :class="`ma-1 ${interval == 365 ? 'default-button' : ''}`" @click.native="getReport(365)" :loading="loading">1 ano</v-btn>

                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :class="`ma-1 ${typeof(interval) == 'object' ? 'default-button' : ''}`" :loading="loading" v-bind="attrs" v-on="on">Intervalo personalizado</v-btn>
                    </template>
                    <v-date-picker locale="pt-BR" range v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                            Cancelar
                        </v-btn>
                        <v-btn text color="primary" @click="saveDate(date)">
                            Salvar
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>

        <div>
            <div v-if="user.admin">
                <h2 class="mt-4 mb-2">Base de usuários</h2>
                <v-row>
                    <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                        <v-card class="mx-auto pt-4 pb-4 blue-gradient" flat shaped>
                            <v-card-title>
                                <v-icon class="icon" left> mdi-account-multiple-outline </v-icon>
                                <v-spacer></v-spacer>
                                <div right class="div-text">
                                    <v-row class="right">
                                        <span class="text-h3">
                                            <animated-number :value="card_total_users" round="1" :duration="600" />
                                        </span>
                                    </v-row>
                                    <v-row>
                                        <span class="subtitle-1">Base total</span>
                                    </v-row>
                                </div>
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                        <v-card class="mx-auto pt-4 pb-4 blue-gradient" flat shaped>
                            <v-card-title>
                                <v-icon class="icon" left> mdi-account-plus-outline </v-icon>
                                <v-spacer></v-spacer>
                                <div right class="div-text">
                                    <v-row class="right">
                                        <span class="text-h3">
                                            <animated-number :value="card_24h_users" round="1" :duration="600" />
                                        </span>
                                    </v-row>
                                    <v-row>
                                        <span class="subtitle-1">Novos usuários (24 horas)</span>
                                    </v-row>
                                </div>
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                        <v-card class="mx-auto pt-4 pb-4 blue-gradient" flat shaped>
                            <v-card-title>
                                <v-icon class="icon" left> mdi-account-plus-outline </v-icon>
                                <v-spacer></v-spacer>
                                <div right class="div-text">
                                    <v-row class="right">
                                        <span class="text-h3">
                                            <animated-number :value="card_30d_users" round="1" :duration="600" />
                                        </span>
                                    </v-row>
                                    <v-row>
                                        <span class="subtitle-1">Novos usuários (30 dias)</span>
                                    </v-row>
                                </div>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <div v-if="user.admin" class="mt-8">
                <h2 class="mt-4 mb-2">Evolução da base</h2>
                <v-row class="mt-2">
                    <v-col cols="12">
                        <div id="chart1">
                            <apexChart ref="chart1" height="350" type="line" :options="chart1Options" :series="series1"></apexChart>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div>
                <h2 class="mt-4 mb-2">Testes realizados - {{ getIntervalName }}</h2>
                <v-row>
                    <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                        <v-card class="mx-auto pt-4 pb-4 blue-gradient" flat shaped>
                            <v-card-title>
                                <v-icon class="icon" left> mdi-test-tube </v-icon>
                                <v-spacer></v-spacer>
                                <div right class="div-text">
                                    <v-row class="right">
                                        <span class="text-h3">
                                            <animated-number :value="card_tests" round="1" :duration="600" />
                                        </span>
                                    </v-row>
                                    <v-row>
                                        <span class="subtitle-1">Total de testes</span>
                                    </v-row>
                                </div>
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                        <v-card class="mx-auto pt-4 pb-4 blue-gradient" flat shaped>
                            <v-card-title>
                                <v-icon class="icon" left> mdi-test-tube </v-icon>
                                <v-icon class="icon" color="success" small> mdi-minus-circle </v-icon>
                                <v-spacer></v-spacer>
                                <div right class="div-text">
                                    <v-row class="right">
                                        <span class="text-h3">
                                            <animated-number :value="card_tests_neg" round="1" :duration="600" />
                                        </span>
                                    </v-row>
                                    <v-row>
                                        <span class="subtitle-1">Testes negativos</span>
                                    </v-row>
                                </div>
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                        <v-card class="mx-auto pt-4 pb-4 blue-gradient" flat shaped>
                            <v-card-title>
                                <v-icon class="icon" left> mdi-test-tube </v-icon>
                                <v-icon class="icon" color="red" small> mdi-plus-circle </v-icon>
                                <v-spacer></v-spacer>
                                <div right class="div-text">
                                    <v-row class="right">
                                        <span class="text-h3">

                                            <animated-number :value="card_tests_pos" round="1" :duration="600" />
                                        </span>
                                    </v-row>
                                    <v-row>
                                        <span class="subtitle-1">Testes positivos</span>
                                    </v-row>
                                </div>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <v-row class="mt-6" v-if="user.admin">
                <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                    <h2 class="mt-4 mb-2">Top 5 produtos - {{ getIntervalName }}</h2>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Produto
                                    </th>
                                    <th class="text-left">
                                        Testes realizados
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in chart_top_tests" :key="item.name">
                                    <td>{{ item._id }}</td>
                                    <td>{{ item.all }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" md="8" lg="8" sm="12" xs="12">
                    <h2 class="mt-4 mb-2">Testes - {{ getIntervalName }}</h2>
                    <v-col cols="12">
                        <div id="chart2">
                            <apexChart ref="chart2" height="300" type="line" :options="chart2Options" :series="series2"></apexChart>
                        </div>
                    </v-col>
                </v-col>
            </v-row>

            <v-row class="mt-6" v-if="!user.admin">
                <v-col cols="12">
                    <h2 class="mt-4 mb-2">Testes - {{ getIntervalName }}</h2>
                    <v-col cols="12">
                        <div id="chart2">
                            <apexChart ref="chart2" height="300" type="line" :options="chart2Options" :series="series2"></apexChart>
                        </div>
                    </v-col>
                </v-col>
            </v-row>


            <v-row class="mt-6" v-if="this.series4.length > 0">
                <v-col cols="12" lg="6" md="6" sm="12">
                    <h2 class="mt-4">Resultados por volume (litros) - {{ getIntervalName }}</h2>
                    <span class="mb-2" style="font-size: 14px;">Distribuição do volume de leite em litros por resultado, para os testes onde o volume foi preenchido.</span>
                    <v-row>
                        <v-col cols="12">
                            <div id="chart3">
                                <apexChart ref="chart3" type="pie" height="400" :options="chart3Options" :series="series3"></apexChart>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="12">
                    <h2 class="mt-4">Positivos por volume (litros) - {{ getIntervalName }}</h2>
                    <span class="mb-2" style="font-size: 14px;">Distribuição do volume de leite com resultado positivo, em litros por origem, para os testes onde o volume e a origem foram preenchidos.</span>
                    <v-row>
                        <v-col cols="12">
                            <div id="chart4">
                                <apexChart ref="chart4" type="pie" height="400" :options="chart4Options" :series="series4"></apexChart>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="mt-6" v-if="origins && origins.length > 0">
                <v-col cols="12">
                    <h2 class="mt-4 mb-2">Top origens por volume - {{ getIntervalName }}</h2>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Origem
                                    </th>
                                    <th class="text-left">
                                        Testes realizados
                                    </th>
                                    <th class="text-left">
                                        Testes positivos
                                    </th>
                                    <th class="text-left">
                                        Testes negativos
                                    </th>
                                    <th class="text-left">
                                        Volume total (litros)
                                    </th>
                                    <th class="text-left">
                                        Volume positivo (litros)
                                    </th>
                                    <th class="text-left">
                                        % de positivo (volume)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in origins" :key="item.name">
                                    <td>{{ item._id }}</td>
                                    <td>{{ item.pos_tests + item.neg_tests }}</td>
                                    <td>{{ item.pos_tests || 0 }}</td>
                                    <td>{{ item.neg_tests || 0 }}</td>
                                    <td>{{ item.pos_vol + item.neg_vol }}</td>
                                    <td>{{ item.pos_vol }}</td>
                                    <td>{{ (item.pos_vol + item.neg_vol) == 0 ? 0 : ((item.pos_vol * 100) / (item.pos_vol + item.neg_vol)).toFixed(1) }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>



    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import AnimatedNumber from "animated-number-vue";

export default {
    name: "DashboardView",

    data() {
        return {
            user: {},
            loading: false,
            interval: 30,

            menu: false,
            date: null,

            card_total_users: 0,
            card_24h_users: 0,
            card_30d_users: 0,

            card_tests: 0,
            card_tests_pos: 0,
            card_tests_neg: 0,

            chart_top_tests: [],
            chart_tests_with_results: [],

            series1: [],
            series2: [],
            series3: [],
            series4: [],

            origins: [],

            chart1Options: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: true
                },
                colors: ['#6261D4'],
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },

            chart2Options: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: true
                },
                colors: ['#6261D4', '#66BB6A', '#EF5350'],
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },


            chart3Options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                colors: ['#59e130', '#ed5e5e'],
                labels: ['Negativos', 'Positivos'],
                legend: {
                    position: 'bottom',
                    fontSize: '12px',
                },
            },

            chart4Options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: [],
                legend: {
                    position: 'bottom',
                    fontSize: '12px',
                },
            },
        };
    },

    components: {
        AnimatedNumber
    },

    async mounted() {
        const user = await Api.getRemoteUser();

        if (user && user._id) {
            this.user = user;
        }
        else {
            Api.logout();
        }

        await this.getReport(this.interval);
    },

    computed: {
        getIntervalName() {
            switch (this.interval) {
                case 1:
                    return 'Hoje'
                case 7:
                    return '7 dias'
                case 30:
                    return '30 dias'
                case 90:
                    return '3 meses'
                case 180:
                    return '6 meses'
                case 365:
                    return '1 ano'

                default:
                    return 'Intervalo personalizado'
            }
        },
    },

    methods: {
        saveDate(date) {
            this.menu = false;
            this.getReport(date);
        },

        async getReport(interval) {
            this.loading = true;
            this.interval = interval;
            const resp = await Api.getDashboardData(this.interval);
            this.loading = false;

            if (resp && resp.error) {
                return emitToastr("error", resp.message)
            }

            if (resp && resp.message) {
                if (this.user.admin) {
                    if (resp.message.card_total_users) this.card_total_users = resp.message.card_total_users;
                    if (resp.message.card_24h_users) this.card_24h_users = resp.message.card_24h_users;
                    if (resp.message.card_30d_users) this.card_30d_users = resp.message.card_30d_users;

                    if (resp.message.chart_users) {
                        let cat = [];
                        let serie = { name: "Novos usuários", data: [] };

                        resp.message.chart_users.forEach(i => {
                            cat.push(i._id);
                            serie.data.push(i.all);
                        });

                        this.series1 = [serie];
                        this.chart1Options.xaxis.categories = cat;
                        if (this.$refs.chart1) this.$refs.chart1.refresh();
                    }

                    if (resp.message.chart_top_tests) {
                        this.chart_top_tests = resp.message.chart_top_tests;
                        if (this.chart_top_tests.length > 5) this.chart_top_tests.length = 5;
                    }
                }

                this.card_tests = resp.message.card_tests || 0;
                this.card_tests_pos = resp.message.card_tests_pos || 0;
                this.card_tests_neg = resp.message.card_tests_neg || 0;

                if (resp.message.chart_tests_with_results) {
                    let cat = [];
                    let serie1 = { name: "Total de testes", data: [] };
                    let serie2 = { name: "Negativos", data: [] };
                    let serie3 = { name: "Positivos", data: [] };

                    resp.message.chart_tests_with_results.forEach(i => {
                        cat.push(i._id);
                        serie1.data.push(i.total);
                        serie2.data.push(i.neg);
                        serie3.data.push(i.pos);
                    });

                    this.series2 = [serie1, serie2, serie3];
                    this.chart2Options.xaxis.categories = cat;
                    if (this.$refs.chart2) this.$refs.chart2.refresh();
                }

                if (resp.message.volume_chart) {
                    let volumes = { pos: 0, neg: 0 };

                    this.series3 = [];
                    this.series4 = [];
                    this.chart4Options.labels = [];
                    this.origins = [];

                    resp.message.volume_chart.forEach(item => {
                        volumes.pos += item.pos_vol;
                        volumes.neg += item.neg_vol;

                        this.series4.push(item.pos_vol);
                        this.chart4Options.labels.push(item._id != '' ? item._id : 'NÃO INFORMADO');


                        if (item._id != '') this.origins.push(item);
                    });

                    if (this.origins.length > 20) this.origins.length == 20;

                    //dogão
                    this.origins = this.origins.sort((a, b) => { return (a.pos_vol > b.pos_vol) ? -1 : ((b.pos_vol > a.pos_vol) ? 1 : 0) });

                    this.series3 = [volumes.neg, volumes.pos];
                    if (this.$refs.chart3) this.$refs.chart3.refresh();
                    if (this.$refs.chart4) this.$refs.chart4.refresh();
                }
            }
        }
    },
};
</script>

<style scoped>
.icon {
    font-size: 60px;
    color: #6261D4;
}

.mx-auto {}

.div-text {
    margin-right: 20px;
}

.card-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blue-gradient {
    background: rgb(242, 242, 255);
    background: linear-gradient(145deg, rgba(242, 242, 255, 1) 0%, rgba(239, 239, 255, 1) 50%);
    color: #6261D4;
}

.right {
    justify-content: flex-end !important;
}
</style>
