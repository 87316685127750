<template>
    <div class="menu-page">
        <h1>Importar testes</h1>

        <div class="mt-8 mb-2">

            <v-row>
                <v-col cols="12" class="d-flex">
                    <v-file-input dense accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" label="Carregue aqui a planilha com a lista de resultados de teste" v-model="fileToUpload"></v-file-input>
                    <v-btn @click="importResults(false)" class="ml-6 default-button">Analisar planilha</v-btn>
                </v-col>
            </v-row>


        </div>

        <div class="mt-6" v-if="imported === true">
            <div>
                <h4>{{ `Foram processados ${valid_results.length} resultados válidos e ${invalid_results_indexes.length} inválidos.` }}</h4>
                <h5 v-if="invalid_results_indexes.length > 0">{{ `Resultados inválidos nas linhas: ${invalid_results_indexes}` }}</h5>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn @click="clear" class="ml-6 default-button" color="error">Descartar</v-btn>
                    <v-btn @click="importResults(true)" class="ml-6 default-button">Importar testes</v-btn>
                </div>
            </div>
            <v-simple-table dense class="mt-2">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Data
                            </th>
                            <th class="text-left">
                                Nome
                            </th>
                            <th class="text-left">
                                Tipo
                            </th>
                            <th class="text-left">
                                Origem
                            </th>
                            <th class="text-left">
                                Volume
                            </th>
                            <th class="text-left">
                                Resultado
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in valid_results" :key="index">
                            <td>{{ new Date(item.data).toLocaleDateString() }}</td>
                            <td>{{ item.nome }}</td>
                            <td>{{ item.tipo }}</td>
                            <td>{{ item.origem }}</td>
                            <td>{{ item.volume }}</td>
                            <td>{{ item.resultado }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>

        <div>
            <v-dialog v-model="infoDialogVisible" max-width="500">
                <v-card>
                    <v-card-title class="headline">Importante</v-card-title>

                    <v-card-text class="d-flex flex-column">
                        <span>Listas de resultados de testes devem ser planilhas xlsx contendo algumas informações obrigatórias e informações opcionais.</span>
                        <br>
                        <span><b>Campos obrigatórios:</b></span>
                        <span><b>data: </b>data do teste no formato DD/MM/AAAA</span>
                        <span><b>nome: </b>nome do teste ou da amostra</span>
                        <span><b>tipo: </b>tipo do teste</span>
                        <span><b>origem: </b>origem do leite</span>
                        <span><b>volume: </b>volume de leite representado neste teste (se não for aplicável, deixar 0)</span>
                        <span><b>resultado: </b>positivo ou negativo</span>
                        <br>
                        <span>Quaisquer outras colunas serão salvas como informações extras do teste</span>
                        <br>
                        <b>Faça o download da planilha modelo <a href="/planilha_de_testes.xlsx">clicando aqui</a></b>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="gray darken-1" text @click="showHideInfoDialog(false)"> Entendido </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "PrivacyPolicyView",

    data() {
        return {
            infoDialogVisible: true,
            fileToUpload: null,
            buttonLoading: false,
            loading: true,
            imported: false,
            valid_results: [],
            invalid_results_indexes: [],
            total_count: 0
        };
    },

    components: {},

    async mounted() {

    },

    methods: {

        async init() {
            this.loading = true;
            const resp = await Api.getPrivacyPolicy();
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }

                this.object = resp.message;
            }
            this.loading = false;
        },

        async importResults(save = false) {
            this.loading = true;
            let resp = await Api.sendResultsList({}, this.fileToUpload, save);
            this.loading = false;

            if (resp && !resp.error) {
                if (resp.total_count && resp.total_count > 0) {
                    this.imported = true;
                    this.valid_results = resp.valid;
                    this.invalid_results_indexes = resp.invalid;
                    this.total_count = resp.total_count;
                }

                if (resp.message) {
                    emitToastr("success", resp.message);
                    this.clear();
                }
            }
        },

        showHideInfoDialog(show) {
            this.infoDialogVisible = show;
        },

        clear() {
            this.fileToUpload = null;
            this.imported = false;
            this.valid_results = [];
            this.invalid_results_indexes = [];
            this.total_count = 0;
            this.total_count = true;
            this.infoDialogVisible = true;
        },
    },
};
</script>
  
<style scoped></style>
  