<template>
    <div class="menu-page">
        <h1>Testes</h1>

        <v-alert color="#6261d4" class="info-alert" dismissible>
            <v-row align="center">
                <v-col class="grow">
                    Aprenda a gerenciar seus resultados de maneira segura e eficiente assistindo ao tutorial de uso da plataforma web Somaticell.
                </v-col>
                <v-col class="shrink">
                    <v-btn @click.native="tutorialDialog = true">Ver tutorial</v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        Buscar testes
                    </v-card-title>
                    <v-card-subtitle>
                        Insira algum parâmetro de busca ou deixe em branco para visualizar os últimos 1000 registros
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="12" v-if="user.admin">
                                <v-text-field dense v-model="query.userEmail" label="E-mail da conta" outlined hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field dense v-model="query.testType" label="Tipo de teste" outlined hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-select clearable dense v-model="query.readingType" item-text="title" item-value="id" :items="types" label="Tipo de leitura" outlined hide-details="auto"></v-select>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field dense v-model="query.extras.origem" label="Origem" outlined hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="4" md="4">
                                <v-menu v-model="startDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined clearable dense v-model="startDate" label="Data inicial" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="startDate" @input="startDateMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" sm="4" md="4">
                                <v-menu v-model="endDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined clearable dense v-model="endDate" label="Data final" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto"></v-text-field>
                                    </template>
                                    <v-date-picker locale="pt-BR" v-model="endDate" @input="endDateMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12">
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="default-button" @click.native="init">Filtrar</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete v-model="selected_headers" chips small-chips deletable-chips :items="all_headers" multiple item-text="text" item-value="value" outlined label="Ver campos" dense hide-details="auto" @change="changeHeaders"></v-autocomplete>
                            </v-col>

                            <!--
                            <v-col cols="6">
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busca rápida" single-line hide-details clearable></v-text-field>
                            </v-col>
                            -->

                            <v-col cols="12">
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="default-button" @click.native="dialogExport = true">
                                        <v-icon left>mdi-table-arrow-right</v-icon>
                                        Exportar
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table item-key="_id" v-model="selectedItems" show-select dense disable-sort v-if="!loading" :no-data-text="noDataText" :headers="getHeaders" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.readingType`]="{ item }">
                            {{ item.readingType == 'manual' ? "Leitura Manual" : item.readingType == 'imported' ? "Importado" : "Pocket Reader" }}
                        </template>

                        <template v-slot:[`item.created`]="{ item }">
                            {{ new Date(item.created).toLocaleString() }}
                        </template>

                        <template v-slot:[`item.categoryId`]="{ item }">
                            {{ categories[item.categoryId] }}
                        </template>

                        <template v-slot:[`item.imageUrl`]="{ item }">
                            <v-avatar rounded size="40" v-if="item.imageUrl">
                                <img :src="item.imageUrl">
                            </v-avatar>
                        </template>

                        <template v-slot:[`item.finalResult`]="{ item }">
                            <v-chip text-color="white" class="ma-2" :color="item.finalResult == 'Positivo' ? 'red' : 'green'" small>
                                <v-icon small class="mr-1">{{ item.finalResult == 'Positivo' ? 'mdi-plus' : 'mdi-minus' }}</v-icon>
                                {{ item.finalResult }}
                            </v-chip>
                        </template>


                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-4" color="primary" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-eye-outline</v-icon>
                                    </template>
                                    <span>Ver detalhes</span>
                                </v-tooltip>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-card-title class="text-h5">
                    Detalhes do teste
                </v-card-title>
                <v-card-text>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Informação
                                    </th>
                                    <th class="text-left">
                                        Valor
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Data</td>
                                    <td>{{ new Date(object.created).toLocaleDateString() }}</td>
                                </tr>
                                <tr>
                                    <td>E-mail</td>
                                    <td>{{ object.user.email }}</td>
                                </tr>

                                <tr>
                                    <td>Nome do usuário</td>
                                    <td>{{ object.user.name }}</td>
                                </tr>

                                <tr>
                                    <td>Teste</td>
                                    <td>{{ object.testType }}</td>
                                </tr>

                                <tr>
                                    <td>Nome da amostra</td>
                                    <td>{{ object.name }}</td>
                                </tr>

                                <tr v-if="object.categoryId == 3 && object.mastite_custom && object.mastite_custom.animal">
                                    <td>Animal</td>
                                    <td>{{ object.mastite_custom.animal }}</td>
                                </tr>

                                <tr>
                                    <td>Resultado</td>
                                    <td>{{ object.finalResult }}</td>
                                </tr>

                                <tr>
                                    <td>Tipo de leitura</td>
                                    <td>{{ object.readingType == 'manual' ? "Leitura Manual" : object.readingType == 'imported' ? "Importado" : "Pocket Reader" }}</td>
                                </tr>

                                <tr v-if="object.mapResult">
                                    <td>Leituras</td>
                                    <td>
                                        <v-chip text-color="white" class="ma-2" :color="item.result == 'Positivo' ? 'red' : 'green'" small v-for="(item, index) in object.mapResult" :key="index">
                                            <v-icon small class="mr-2">{{ item.result == 'Positivo' ? 'mdi-plus' : 'mdi-minus' }}</v-icon>
                                            {{ `${item.item}: ${item.value}` }}
                                        </v-chip>
                                    </td>
                                </tr>
                                <tr v-if="object.readingType != 'manual' && object.heights">
                                    <td>Alturas</td>
                                    <td>
                                        <v-chip text-color="white" class="ma-2" color="#6261D4" small v-for="(item, index) in JSON.parse(object.heights)" :key="index">
                                            {{ item }}
                                        </v-chip>
                                    </td>
                                </tr>


                                <div class="mt-2 mb-2" v-if="object.extras">
                                    <span><b>INFORMAÇÕES EXTRAS</b></span>
                                    <div class="mt-2" v-for="(listItem, index) in Object.keys(object.extras)" :key="index">
                                        <b>{{ listItem }}: </b> {{ object.extras[listItem] }}
                                    </div>
                                </div>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-divider></v-divider>

                    <div class="mt-6">
                        <div class="image-container pb-4" v-if="object.imageUrl">
                            <v-img class="test-image" :src="object.imageUrl" contain></v-img>
                        </div>

                        <div>
                            <v-row>
                                <v-col cols="12" class="d-flex">
                                    <v-file-input prepend-icon="mdi-camera-outline" dense accept="image/*" :label="object.imageUrl ? 'Trocar a foto' : 'Adicionar foto'" v-model="image" @change="loadImage"></v-file-input>
                                    <v-btn @click="uploadImage" class="ml-6 default-button">{{ object.imageUrl ? 'Trocar foto' : 'Adicionar foto' }}</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </div>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialog = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogExport" max-width="800">
            <v-card>
                <v-card-title class="text-h5">
                    Exportar resultados
                </v-card-title>


                <v-card-text>
                    <v-col cols="12" class="pt-2">
                        <label><b class="text-md">Sobre os resultados que serão exportados</b></label>
                        <v-radio-group class="mt-1" v-model="options.list" column hide-details="auto">
                            <v-radio label="Todos os resultados da tabela" value="all"></v-radio>
                            <v-radio label="Apenas os resultados selecionados" value="selected"></v-radio>
                        </v-radio-group>
                    </v-col>

                    <v-col cols="12" class="pt-2">
                        <label><b class="text-md">Sobre os campos presentes nos resultados <small>(Exemplo: categoria, origem, foto, data, etc...)</small></b></label>
                        <v-radio-group class="mt-1" v-model="options.fields" column hide-details="auto">
                            <v-radio label="Todos os campos disponíveis" value="all"></v-radio>
                            <v-radio label="Apenas os campos selecionados, como exibido na tabela abaixo" value="selected"></v-radio>
                        </v-radio-group>
                    </v-col>

                    <v-col cols="12" class="pt-2">
                        <label><b class="text-md">Sobre o formato do arquivo</b></label>
                        <v-radio-group class="mt-1" v-model="options.fileType" column hide-details="auto">
                            <v-radio label="Excel (xlsx)" value="xlsx"></v-radio>
                            <v-radio label="PDF" value="pdf"></v-radio>
                        </v-radio-group>
                    </v-col>

                    <v-col cols="12" class="pt-2">
                        <v-checkbox label="Receber uma cópia por e-mail" v-model="options.sendEmail" hide-details="auto"></v-checkbox>

                        <v-text-field class="mt-4" v-if="options.sendEmail" dense v-model="options.email" label="E-mail do destinatário" outlined hide-details="auto"></v-text-field>
                    </v-col>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="dialogExport = false">
                        Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="exportTests" :loading="buttonLoading">
                        Exportar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="tutorialDialog" v-if="tutorialDialog" max-width="800">
            <v-card>
                <v-card-title>
                    Tutorial Plataforma Web
                </v-card-title>

                <v-card-text>
                    <vimeo-player ref="player" video-id="899233861" :options="{ responsive: true }" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
const moment = require('moment');
import { saveAs } from 'file-saver';
import { vueVimeoPlayer } from 'vue-vimeo-player';

export default {
    name: "FaqView",

    data() {
        return {
            objects: [],
            selectedItems: [],
            dialogCreateUpdateObject: false,
            dialogExport: false,
            dialog: false,
            search: '',
            image: null,
            imageData: null,
            noDataText: 'Sem dados',
            fileInputMessage: '',
            object: {},
            options: { list: 'all', fields: 'all', fileType: 'xlsx', sendEmail: false, email: '' },
            user: {},
            query: { userEmail: '', testType: '', extras: {} },
            types: [
                { id: "manual", title: "Leitura Manual" },
                { id: "imported", title: "Importado" },
                { id: "pocket_reader", title: "Pocket Reader" }
            ],
            startDateMenu: false,
            endDateMenu: false,
            startDate: "",
            endDate: "",
            categories: {
                "1": "Resíduos de antibiótico no leite",
                "2": "Resíduos de pesticidas",
                "3": "Diagnóstico de mastite bovina",
                "4": "Micotoxinas e microbiologia",
                "5": "Análises físico-químicas",
            },
            all_headers: [],
            selected_headers: [],
            buttonLoading: false,
            loading: true,
            tutorialDialog: false
        };
    },

    // eslint-disable-next-line
    components: { vueVimeoPlayer },

    async mounted() {
        const user = await Api.getRemoteUser();
        this.user = user;
        this.options.email = this.user.email;

        this.all_headers = [
            { text: "Teste", value: "testType" },
            { text: "Categoria", value: "categoryId" },
            { text: "Resultado", value: "finalResult" },
            { text: "Nome do teste", value: "name" },
            { text: "Tipo de leitura", value: "readingType" },
            { text: "Origem", value: "extras.origem" },
            { text: "Volume de leite", value: "extras.volume" },
            { text: "Foto", value: "imageUrl" },
            { text: "Data", value: "created" },
            { text: "Ações", value: "actions" }
        ];

        if (this.user.admin) this.all_headers = [{ text: "E-mail", value: "user.email" }].concat(this.all_headers);

        this.getSavedHeaders();
        this.init();
    },

    computed: {
        getHeaders() {
            return this.all_headers.filter(h => this.selected_headers.includes(h.value));
        }
    },

    methods: {

        getSavedHeaders() {
            try {
                let headers;

                if (window.localStorage.getItem('headers')) {
                    headers = JSON.parse(window.localStorage.headers);
                }
                else {
                    headers = [];
                    this.all_headers.forEach(h => {
                        headers.push(h.value);
                    })
                }

                this.selected_headers = headers;
            }
            catch (err) {
                return;
            }
        },

        changeHeaders(data) {
            window.localStorage.setItem('headers', JSON.stringify(data));
        },

        async init() {
            this.loading = true;

            delete this.query.created;

            if (this.startDate && this.endDate) {
                this.query.created = { "$gte": moment(this.startDate).startOf('day').toDate(), "$lte": moment(this.endDate).endOf('day').toDate() };
            }
            else {
                if (this.startDate) {
                    this.query.created = { "$gte": moment(this.startDate).startOf('day').toDate() };
                }

                if (this.endDate) {
                    this.query.created = { "$lte": moment(this.endDate).endOf('day').toDate() };
                }
            }

            const resp = await Api.getTests(this.query);
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }
                this.objects = resp.message;
            }
            this.loading = false;
        },

        async exportTests() {
            let data;

            this.options.list == 'all' ? data = this.objects : data = this.selectedItems;
            this.buttonLoading = true;
            const resp = await Api.exportTests(data, this.selected_headers, this.options);
            this.buttonLoading = false;
            const filename = `${new Date().toLocaleString().split('/').join('_').split(',').join('').split(':').join('_')}`

            if (!resp.error) {
                emitToastr('success', 'Relatório exportado com sucesso!');
                this.dialogExport = false;
            }

            const blob = new Blob([resp], {
                type: this.options.fileType == 'xlsx' ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "application/pdf"
            });

            saveAs(blob, this.options.fileType == 'xlsx' ? `Testes_Somaticell_${filename}.xlsx` : `Testes_Somaticell_${filename}.pdf`);
        },

        openNew() {
            this.object = {};
            this.dialogCreateUpdateObject = true;
        },

        loadImage(data) {
            this.imageData = data;
        },

        async uploadImage() {
            if (!this.image) return emitToastr("error", "É necessário selecionar uma imagem no campo ao lado.");
            this.loading = true;
            let resp = await Api.uploadImage(this.imageData);
            this.loading = false;

            if (resp && !resp.error && resp.message) {
                this.object.imageUrl = resp.message;

                resp = await Api.insertTestImageUrl(this.object._id, resp.message);
                if (!resp.error) emitToastr("success", "Imagem enviada com sucesso.");
            }

            if (resp && resp.error) emitToastr("error", "Erro ao enviar a imagem. Tente novamente.");
        },
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

.item-image {
    max-height: 100px;
    max-width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.text-md {
    font-size: 16px;
}

.image-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.test-image {
    max-height: 600px;
}

.info-alert {
    opacity: 0.8;
    color: white;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  